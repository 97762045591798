import React from 'react';
import autoBind from 'react-autobind';

import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
// import * as Helpers from '../Helpers.js';

//import the Darwin API classes
// import API from '../API.js';

//import * as ExampleData from '../data/exampleData.json'

// import ImageHomeBackground from '../images/PPB/PPBWebsiteBackground.jpg'
import ImageBusinessIceSkatingRink from '../images/PPB/Business_IceSkatingRink.jpg'
import ImageBusinessPumpkinPatch from '../images/PPB/Business_PumpkinPatch.jpg'
import ImageBusinessWinterWonderland from '../images/PPB/Business_WinterWonderland.jpg'
import ImageBusinessDrone from '../images/PPB/Business_DroneShow.jpg'
import ImageBusinessFleet from '../images/PPB/Business_Fleet.jpg'

import ImageHoustonPumpkinFestival from '../images/PPB/HoustonPumpkinFest.jpg'
import ImageAustinPumpkinFestival from '../images/PPB/AustinPumpkinFest.jpg'
import ImageSanAntonioPumpkinFestival from '../images/PPB/SanAntonioPumpkinFest.jpg'

import ImagePartnerLongCenter from '../images/PPB/LongCenter.png'
import ImagePartnerDiscoveryGreen from '../images/PPB/DiscoveryGreen.png'
import ImagePartnerPearl from '../images/PPB/Pearl.png'
import ImagePartnerDowntownDallas from '../images/PPB/DowntownDallas.png'
import ImagePartnerHEB from '../images/PPB/HEB.png'
import ImagePartnerOmniHotels from '../images/PPB/OmniHotels.png'
import ImagePartnerSeamlessCapital from '../images/PPB/SeamlessCapital.png'
import ImagePartnerGrayStPartners from '../images/PPB/GrayStPartners.png'
import ImagePartnerOxbow from '../images/PPB/Oxbow.png'
import ImagePartnerSouthlake from '../images/PPB/Southlake.png'
import ImagePartnerBoerne from '../images/PPB/Boerne.png'
import ImagePartnerDallas from '../images/PPB/Dallas.png'
import ImagePartnerHouston from '../images/PPB/Houston.png'
import ImagePartnerAustin from '../images/PPB/Austin.png'
import ImagePartnerSanAntonio from '../images/PPB/SanAntonio.png'

import ImageGallery1 from '../images/PPB/Gallery/1.jpg'
// import ImageGallery2 from '../images/PPB/Gallery/2.jpg'
import ImageGallery3 from '../images/PPB/Gallery/3.jpg'
import ImageGallery4 from '../images/PPB/Gallery/4.jpg'
import ImageGallery5 from '../images/PPB/Gallery/5.jpg'
import ImageGallery6 from '../images/PPB/Gallery/6.jpg'
// import ImageGallery7 from '../images/PPB/Gallery/7.jpg'
// import ImageGallery8 from '../images/PPB/Gallery/8.jpg'
import ImageGallery9 from '../images/PPB/Gallery/9.jpg'
// import ImageGallery10 from '../images/PPB/Gallery/10.jpg'
import ImageGallery11 from '../images/PPB/Gallery/11.jpg'
// import ImageGallery12 from '../images/PPB/Gallery/12.jpg'
import ImageGallery13 from '../images/PPB/Gallery/13.jpg'
// import ImageGallery14 from '../images/PPB/Gallery/14.jpg'
// import ImageGallery15 from '../images/PPB/Gallery/15.jpg'
import ImageGallery16 from '../images/PPB/Gallery/16.jpg'
import ImageGallery17 from '../images/PPB/Gallery/17.jpg'
// import ImageGallery18 from '../images/PPB/Gallery/18.jpg'
import ImageGallery19 from '../images/PPB/Gallery/19.jpg'
// import ImageGallery20 from '../images/PPB/Gallery/20.jpg'
import ImageGallery21 from '../images/PPB/Gallery/21.jpg'
import ImageGallery22 from '../images/PPB/Gallery/22.jpg'
import ImageGallery23 from '../images/PPB/Gallery/23.jpg'
import ImageGallery24 from '../images/PPB/Gallery/24.jpg'
import ImageGallery25 from '../images/PPB/Gallery/25.jpg'
import ImageGallery26 from '../images/PPB/Gallery/26.jpg'
// import ImageGallery27 from '../images/PPB/Gallery/27.jpg'
import ImageGallery28 from '../images/PPB/Gallery/28.jpg'
// import ImageGallery29 from '../images/PPB/Gallery/29.jpg'
import ImageGallery30 from '../images/PPB/Gallery/30.jpg'
import ImageGallery31 from '../images/PPB/Gallery/31.jpg'
import ImageGallery32 from '../images/PPB/Gallery/32.jpg'

import ImageGallery101 from '../images/PPB/Gallery/101.jpg'
// import ImageGallery102 from '../images/PPB/Gallery/102.jpg'
import ImageGallery103 from '../images/PPB/Gallery/103.jpg'
import ImageGallery104 from '../images/PPB/Gallery/104.jpg'
import ImageGallery105 from '../images/PPB/Gallery/105.jpg'
import ImageGallery106 from '../images/PPB/Gallery/106.jpg'
import ImageGallery107 from '../images/PPB/Gallery/107.jpg'
import ImageGallery108 from '../images/PPB/Gallery/108.jpg'
import ImageGallery109 from '../images/PPB/Gallery/109.jpg'
// import ImageGallery110 from '../images/PPB/Gallery/110.jpg'
import ImageGallery111 from '../images/PPB/Gallery/111.jpg'
import ImageGallery112 from '../images/PPB/Gallery/112.jpg'
import ImageGallery114 from '../images/PPB/Gallery/114.jpg'
import ImageGallery115 from '../images/PPB/Gallery/115.jpg'
import ImageGallery116 from '../images/PPB/Gallery/116.jpg'
// import ImageGallery117 from '../images/PPB/Gallery/117.jpg'
// import ImageGallery118 from '../images/PPB/Gallery/118.jpg'
import ImageGallery119 from '../images/PPB/Gallery/119.jpg'
import ImageGallery120 from '../images/PPB/Gallery/120.jpg'
// import ImageGallery121 from '../images/PPB/Gallery/121.jpg'
// import ImageGallery122 from '../images/PPB/Gallery/122.jpg'

// import { extend } from 'jquery';
// import ImageHomePage from '../images/Floww/HomePage.png'
// import ImageReadyToFlow from '../images/Floww/Ready to Floww.jpg'
// import ImageYoga3 from '../images/Floww/HomeYoga3.png'

export class Home extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
      abilities: [],
      actions: [],

      selectedImage: false,
      selectedImageIndex: -1,
      nextImage: false,
      previousImage: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          //this.updateFees(data)
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false
      }
    }, () => {
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }

  imageSelected(index, item) {
    console.log(index, item)

    let nextImageIndex = (index + 1) % galleryImages.length
    let previousImageIndex = index - 1
    if (previousImageIndex < 0) {
      previousImageIndex = galleryImages.length - 1
    }

    this.setState({
      selectedImage: item,
      selectedImageIndex: index,
      nextImage: galleryImages[nextImageIndex],
      previousImage: galleryImages[previousImageIndex],
    })
  }

  closeImage() {
    this.setState({
      selectedImage: false,
      selectedImageIndex: -1,
      nextImage: false,
      previousImage: false,
    })
  }

  handleMovePrev() {
    this.setState((old) => {
      let newIndi = old.selectedImageIndex - 1
      if (newIndi < 0) {
        newIndi = galleryImages.length - 1
      }
      let next = old.selectedImageIndex
      let prev = newIndi - 1
      if (prev < 0) {
        prev = galleryImages.length - 1
      }
      console.log(prev, newIndi, next)
      return {
        selectedImage: galleryImages[newIndi],
        selectedImageIndex: newIndi,
        nextImage: galleryImages[next],
        previousImage: galleryImages[prev],
      }
    })
  }

  handleMoveNext() {
    this.setState((old) => {
      let newIndi = (old.selectedImageIndex + 1) % galleryImages.length
      let next = (old.selectedImageIndex + 2) % galleryImages.length
      let prev = old.selectedImageIndex

      return {
        selectedImage: galleryImages[newIndi],
        selectedImageIndex: newIndi,
        nextImage: galleryImages[next],
        previousImage: galleryImages[prev],
      }
    })
  }

  render() {

    return (
      <div className="Home">
        <HomeTopLinks />
        <div className="HomeTop">
          <div className="HomeTopBackground">
          </div>
        </div>
        <div className="HomeBusinesses">
          <div className="HomeBusinessesContent">
              {/* Winter Wonderlands */}
              <HomeBusiness image={ImageBusinessWinterWonderland}
                title="Winter Wonderlands"
                description="Step into our Winter Wonderlands, where snow-dusted scenes and twinkling lights create a magical world for everyone to explore."
                link="/winterwonderland"
                />
              {/* Pumpkin Festivals */}
              <HomeBusiness image={ImageBusinessPumpkinPatch}
                title="Pumpkin Festivals"
                description="Celebrate the magic of autumn at our Pumpkin Festivals, where vibrant pumpkins and seasonal delights bring joy to all ages."
                link="/pumpkinfestival"
                />
              {/* Ice Skating Rinks */}
              <HomeBusiness image={ImageBusinessIceSkatingRink}
                title="Ice Skating Rinks"
                description="Glide across our enchanting ice rinks, where skaters of all ages are transported into a sparkling winter fairy tale."
                link="/iceskating"
                />
              {/* Drone Shows */}
              <HomeBusiness image={ImageBusinessDrone}
                title="Drone Light Shows"
                description="Marvel as our mesmerizing drone shows light up the night sky, transforming it into a canvas of futuristic wonder."
                link="/drones"
                />
              {/* Fun Fleet */}
              <HomeBusiness image={ImageBusinessFleet}
                title="Fun Fleet"
                description="Bring the magic to your event with our Fun Fleet—rent unforgettable attractions for all ages and make your celebration a smashing success!"
                link="funfleet"
                />
          </div>
        </div>
        <div className="FeaturedEvents">
          <div className="FeaturedEventsTitle">
            Upcoming Events
          </div>
          <div className="FeaturedEventsList">
            <HomeFeaturedEvent image={ImageHoustonPumpkinFestival}
              title="Houston Pumpkin Festival"
              description="Sep 20 - Oct 27"
              url="https://geoji.com/g/6612?r=3542"
              />
            <HomeFeaturedEvent image={ImageAustinPumpkinFestival}
              title="Austin Pumpkin Festival"
              description="Sep 27 - Oct 27"
              url="https://geoji.com/g/9796?r=3543"
              />
            <HomeFeaturedEvent image={ImageSanAntonioPumpkinFestival}
              title="San Antonio Pumpkin Festival"
              description="Sep 27 - Oct 27"
              url="https://geoji.com/g/9797?r=3544"
              />
          </div>
        </div>
        <div className="Partners">
          <div className="PartnersWork">
            <HomePartner image={ImagePartnerLongCenter} title="Long Center" />
            <HomePartner image={ImagePartnerGrayStPartners} title="Graystreet Partners" />
            <HomePartner image={ImagePartnerDiscoveryGreen} title="Discovery Green" />
            <HomePartner image={ImagePartnerOmniHotels} title="Omni Hotels" />
            <HomePartner image={ImagePartnerPearl} title="Pearl" />
            <HomePartner image={ImagePartnerHEB} title="HEB" />
            <HomePartner image={ImagePartnerOxbow} title="Oxbow Reality" />
            <HomePartner image={ImagePartnerDowntownDallas} title="Downtown Dallas" />
            <HomePartner image={ImagePartnerSeamlessCapital} title="Seamless Capital" />
          </div>
          <div className="PartnersCities">
            <HomePartner image={ImagePartnerSouthlake} title="Southlake" />
            <HomePartner image={ImagePartnerBoerne} title="Boerne" />
            <HomePartner image={ImagePartnerDallas} title="Dallas" />
            <HomePartner image={ImagePartnerHouston} title="Houston" />
            <HomePartner image={ImagePartnerAustin} title="Austin" />
            <HomePartner image={ImagePartnerSanAntonio} title="San Antonio" />
          </div>
        </div>
        <div className="Gallery">
          <Gallery
            images={galleryImages}
            onClick={this.imageSelected.bind(this)}
            enableImageSelection={false}
            rowHeight="240px"
          />
          { this.state.selectedImage !== false &&
            <Lightbox
              mainSrc={this.state.selectedImage.src}
              imageTitle={this.state.selectedImage.caption}
              mainSrcThumbnail={this.state.selectedImage.src}
              nextSrc={this.state.nextImage.src}
              nextSrcThumbnail={this.state.nextImage.src}
              prevSrc={this.state.previousImage.src}
              prevSrcThumbnail={this.state.previousImage.src}
              onCloseRequest={this.closeImage.bind(this)}
              onMovePrevRequest={this.handleMovePrev.bind(this)}
              onMoveNextRequest={this.handleMoveNext.bind(this)}
            />
          }
        </div>
      </div>
    )
  }
}

export class HomeTopLinks extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
    autoBind(this)
  }

  toggleMenu() {
    this.setState((old) => {
      return {
        expanded: !old.expanded
      }
    })
  }

  render() {
    return (
      <div className={"HomeTopLinks " + (this.state.expanded ? "HomeTopLinksOpen" : "")}>
        <a className="HomeTopLink" style={{marginLeft:"12px"}} href="/home">Home</a>
        <div className="HomeTopLinkSpacer"></div>
        <a className="HomeTopLink" href="/contact">Contact</a>
        <a className="HomeTopLink" href="/about">About</a>
        <div className={"TopBarMenu " + (this.state.expanded ? "TopBarMenuExpanded" : "")} onClick={this.toggleMenu}>
          <div className={"menu-btn " + (this.state.expanded ? "open" : "")}>
            <div className="menu-btn__burger"></div>
          </div>
        </div>
        <div className="HomeTopLinks2">
          <a className="HomeTopLink2" href="/home">Home</a>
          <a className="HomeTopLink2" href="/contact">Contact</a>
          <a className="HomeTopLink2" href="/about">About</a>
        </div>
      </div>
    )
  }
}

export class HomeBusiness extends React.Component {

  render() {
    return (
      <div className="HomeBusiness">
        <div className="HomeBusinessImageBox">
          <div className="HomeBusinessImage">
            <img src={this.props.image} alt={this.props.title} />
          </div>
        </div>
        <div className="HomeBusinessTitle">
          {this.props.title}
        </div>
        <div className="HomeBusinessDescription">
          {this.props.description}
        </div>
        <a href={this.props.link} className="HomeBusinessLink">Learn More</a>
      </div>
    )
  }
}

export class HomeFeaturedEvent extends React.Component {

  render() {
    return (
      <div className="FeaturedEvent">
        <a href={this.props.url} target="_blank" rel="noopener noreferrer">
        <div className="FeaturedEventImage">
          <img src={this.props.image} alt={this.props.title} />
        </div>
        <div className="FeaturedEventContent">
          <div className="FeaturedEventDescription">
            {this.props.description}
          </div>
          <div className="FeaturedEventTitle">
            {this.props.title}
          </div>
        </div>
        </a>
      </div>
    )
  }
}

export class HomePartner extends React.Component {
  render() {
    return (
      <div className="Partner">
        <img src={this.props.image} alt={this.props.title} />
      </div>
    )
  }
}

export const galleryImages = [
  // {
  //   src: ImageGallery2,
  //   width: 641,
  //   height: 481,
  //   caption: "Train Ride at Town Square",
  // },
  {
    src: ImageGallery3,
    width: 642,
    height: 856,
    caption: "Corn Pit",
  },
  {
    src: ImageGallery4,
    width: 642,
    height: 852,
    caption: "Train Ride at Town Square",
  },
  {
    src: ImageGallery5,
    width: 641,
    height: 481,
    caption: "Puppy at San Antonio Pumpkin Festival",
  },
  {
    src: ImageGallery6,
    width: 428,
    height: 570,
    caption: "Pumpkins",
  },
  // {
  //   src: ImageGallery7,
  //   width: 428,
  //   height: 762,
  //   caption: "Pink House",
  // },
  // {
  //   src: ImageGallery8,
  //   width: 428,
  //   height: 570,
  //   caption: "Gourds",
  // },
  {
    src: ImageGallery9,
    width: 428,
    height: 570,
    caption: "Head in the Clouds",
  },
  {
    src: ImageGallery1,
    width: 642,
    height: 856,
    caption: "San Antonio Pumpkin Festival",
  },
  // {
  //   src: ImageGallery10,
  //   width: 428,
  //   height: 570,
  //   caption: "I Love San Antonio",
  // },
  {
    src: ImageGallery11,
    width: 428,
    height: 570,
    caption: "Pumpkin Juniors",
  },
  // {
  //   src: ImageGallery12,
  //   width: 428,
  //   height: 570,
  //   caption: "I Love Pumpkins So Much",
  // },
  {
    src: ImageGallery13,
    width: 428,
    height: 448,
    caption: "Barbie House",
  },
  // {
  //   src: ImageGallery14,
  //   width: 428,
  //   height: 570,
  //   caption: "Oh My Gourd",
  // },
  // {
  //   src: ImageGallery15,
  //   width: 642,
  //   height: 642,
  //   caption: "Overhead Pumpkin Shot",
  // },
  {
    src: ImageGallery16,
    width: 642,
    height: 642,
    caption: "Hay Alamo",
  },
  {
    src: ImageGallery17,
    width: 642,
    height: 1141,
    caption: "Oh My Gourd",
  },
  // {
  //   src: ImageGallery18,
  //   width: 641,
  //   height: 1374,
  //   caption: "Corn Pit at Town Square",
  // },
  {
    src: ImageGallery19,
    width: 642,
    height: 856,
    caption: "There's no place like home",
  },
  // {
  //   src: ImageGallery20,
  //   width: 642,
  //   height: 856,
  //   caption: "Falling for you",
  // },
  {
    src: ImageGallery21,
    width: 642,
    height: 856,
    caption: "Pumpkin Nights",
  },
  {
    src: ImageGallery22,
    width: 642,
    height: 856,
    caption: "Fun in the Corn Pit",
  },
  {
    src: ImageGallery23,
    width: 642,
    height: 642,
    caption: "San Antonio Pumpkin Festival",
  },
  {
    src: ImageGallery24,
    width: 642,
    height: 856,
    caption: "Proposal at the Patch",
  },
  {
    src: ImageGallery25,
    width: 642,
    height: 856,
    caption: "Corn Pit",
  },
  {
    src: ImageGallery26,
    width: 642,
    height: 856,
    caption: "Family Photos",
  },
  // {
  //   src: ImageGallery27,
  //   width: 642,
  //   height: 856,
  //   caption: "Corn Pit with the kids",
  // },
  {
    src: ImageGallery28,
    width: 642,
    height: 856,
    caption: "Bouncy Bull Rides",
  },
  // {
  //   src: ImageGallery29,
  //   width: 642,
  //   height: 856,
  //   caption: "Falling for You",
  // },
  {
    src: ImageGallery30,
    width: 642,
    height: 642,
    caption: "Jumpkin' Pumpkin",
  },
  {
    src: ImageGallery31,
    width: 642,
    height: 856,
    caption: "Pumpkin Church",
  },
  {
    src: ImageGallery32,
    width: 641,
    height: 481,
    caption: "I Love Pumpkins So Much",
  },
  //// WINTER WONDERLAND PHOTOS AFTER THIS
  //// WINTER WONDERLAND PHOTOS AFTER THIS
  //// WINTER WONDERLAND PHOTOS AFTER THIS
  {
    src: ImageGallery103,
    width: 416,
    height: 503,
    caption: "Ice Rink at Town Square",
  },
  {
    src: ImageGallery101,
    width: 642,
    height: 856,
    caption: "Santa's Wonderland",
  },
  // {
  //   src: ImageGallery102,
  //   width: 428,
  //   height: 570,
  //   caption: "Train Ride",
  // },
  {
    src: ImageGallery104,
    width: 384,
    height: 512,
    caption: "Junior Skating",
  },
  {
    src: ImageGallery105,
    width: 384,
    height: 512,
    caption: "Tree of Life",
  },
  {
    src: ImageGallery119,
    width: 1199,
    height: 2139,
    caption: "It's a Wonderful Life",
  },
  {
    src: ImageGallery106,
    width: 288,
    height: 512,
    caption: "Ice Skating",
  },
  {
    src: ImageGallery107,
    width: 384,
    height: 512,
    caption: "Town Square Christmas Farm",
  },
  {
    src: ImageGallery109,
    width: 384,
    height: 512,
    caption: "Family Outing",
  },
  {
    src: ImageGallery120,
    width: 1188,
    height: 2134,
    caption: "Winter Wonderland",
  },
  // {
  //   src: ImageGallery110,
  //   width: 384,
  //   height: 512,
  //   caption: "Posh Picnic Party Igloo",
  // },
  {
    src: ImageGallery111,
    width: 512,
    height: 342,
    caption: "Ice Rink at the Long Center",
  },
  {
    src: ImageGallery112,
    width: 410,
    height: 512,
    caption: "Hello from Yoda",
  },
  {
    src: ImageGallery114,
    width: 384,
    height: 512,
    caption: "Posh Picnic Party Igloo",
  },
  {
    src: ImageGallery115,
    width: 384,
    height: 512,
    caption: "Color Changing Forest",
  },
  {
    src: ImageGallery108,
    width: 384,
    height: 512,
    caption: "Dolphin Skate Helpers",
  },
  {
    src: ImageGallery116,
    width: 512,
    height: 384,
    caption: "Best View in the City",
  },
  // {
  //   src: ImageGallery117,
  //   width: 473,
  //   height: 512,
  //   caption: "Downtown Skyline",
  // },
  // {
  //   src: ImageGallery118,
  //   width: 384,
  //   height: 512,
  //   caption: "Texas Forever",
  // },
  // {
  //   src: ImageGallery121,
  //   width: 1193,
  //   height: 2131,
  //   caption: "Just Skatin' Around",
  // },
  // {
  //   src: ImageGallery122,
  //   width: 1188,
  //   height: 2139,
  //   caption: "Date Night",
  // },
];