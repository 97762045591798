import React from 'react';

//React Components
import * as Components from './resources/Components.js';
//import * as Generation from './resources/Generation.js';
import * as Helpers from './resources/Helpers.js';
import * as Home from './resources/screens/Home.js';
import * as About from './resources/screens/About.js';
import * as Contact from './resources/screens/Contact.js';
import * as PumpkinFestival from './resources/screens/PumpkinFestival.js';
import * as WinterWonderland from './resources/screens/WinterWonderland.js';
import * as IceSkating from './resources/screens/IceSkating.js';
import * as Drones from './resources/screens/Drones.js';
import * as FunFleet from './resources/screens/FunFleet.js';
import * as Four0Four from './resources/screens/404.js';

import ImageToastRed from './resources/images/Icons/DeleteRed.svg';
import ImageToastBlue from './resources/images/Icons/CreateBlue.svg';
import ImageToastGreen from './resources/images/Icons/EditGreen.svg';
import ImageToastBlack from './resources/images/Icons/CancelBlack.svg';

//CSS
//import './resources/master.min.css';
import './resources/master.scss';

//import the Darwin API classes
import API from './resources/API.js';

/*
The Entrypoint for the Application. This manages what screen to show, user info, ...
*/
export class App extends React.Component {

  constructor(props) {
    super(props)

    let obj = Object.assign({}, window.location)
    console.log("location", obj)

    //let testData = false

    this.state = {
      view: "Home", //The view that is currently being shown "Home", "Auth".
      subview: "Home", //The subview we are viewing: "Home", "Account"
      tertiaryView: "Home", //The tertiary view we are accessing if there is one.
      fourthView: "Home", //The fourth view or piece of data if there is one.
      render: 1, //A counter to keep track of repetetive actions.
      loadingUserInfo: false, //Are we loading the user information?
      instructorInfo: false, //The instructor info
      instructorLiveClasses: [], //The live classes for this instructor.
      instructorOnDemandClasses: [], //The on demand classes for this instructor.

      popup: false, //is there a popup to show
      popupType: "", //the type of popup to show ("Error", "Success")
      popupTitle: "", //the popup title if there is one
      popupDescription: "", //the popup description if there is one

      confirmation: false, //is there a confirmation to show
      confirmationTitle: "", //the title text
      confirmationDescription: "", //the description text
      confirmationAcccept: "", //the accept text
      confirmationDeny: "", //the denial text
      confirmationAction: () => {}, //the function to call when complete

      toastID: 0, //the current toastID, gets incremented with each creation of a toast.
      toasts: [], //the list of toasts

      userInfo: {}, //The user's information who we are accessing.
      adminInfo: {}, //The actual user who logged in.
      accountTakeover: false, //Has an associate or admin taken over this account?.
    }

    this.updateViewBasedOnPath = this.updateViewBasedOnPath.bind(this)
    this.changeView = this.changeView.bind(this)
    this.reloadUser = this.reloadUser.bind(this)
    this.loadUserInfo = this.loadUserInfo.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.logout = this.logout.bind(this)
    this.showPopup = this.showPopup.bind(this)
    this.dismissPopup = this.dismissPopup.bind(this)
    this.showConfirmation = this.showConfirmation.bind(this)
    this.showConfirmPassword = this.showConfirmPassword.bind(this)
    this.showToast = this.showToast.bind(this)

    this.stopAccessing = this.stopAccessing.bind(this)
    this.accessAccount = this.accessAccount.bind(this)
    this.loggedInUser = this.loggedInUser.bind(this)
    this.firstLogin = this.firstLogin.bind(this)
    this.homeAndReloadUserInfo = this.homeAndReloadUserInfo.bind(this)

    console.log("Mode - " + (Helpers.isDevelopment() ? "Test" : "Live"))
    console.log("App Version - " + Helpers.getAppVersion())

    //turn off logs if in deployment.
    if (window.location.hostname === "peterpansbackyard.com" || window.location.hostname === "www.peterpansbackyard.com") {
      console.log = function() {}
    }
  }

  componentDidMount() {
    let shouldCheckAuth = this.updateViewBasedOnPath()
    console.log("should check auth", shouldCheckAuth)

    //1) Check to see if we are logged in and if not redirect to the login screen
    if (shouldCheckAuth) {
      this.reloadUser()
    } else {
      //get the user info if we are logged in.
      if (API.hasLoggedIn()) {
        this.loadUserInfo()
      }
    }

    //2) Detect back/forward buttons.
    window.onpopstate = () => {
      this.updateViewBasedOnPath()
    }

    //Test out the Toasts
    /*var intervalId = window.setInterval(() => {
      // call your function here
      this.showToast("Hello World", "How are you today, I am great! This is a toast and it is great!", "green")
    }, 1500);*/
  }

  /*
  Updates which view we are on based on the current url path.
  Returns whether or not we should reload user info and check if we are logged in.
  */
  updateViewBasedOnPath() {
    let pathComponents = Helpers.getWindowPathComponents()
    console.log("update view based on path", pathComponents)

    let shouldCheckAuth = false
    switch (pathComponents[0]) {
      case "":
      case "home":
        this.changeView("Home")
        break;
      case "about":
        this.changeView("About")
        break;
      case "contact":
        this.changeView("Contact")
        break;
      case "pumpkinfestival":
        this.changeView("PumpkinFestival")
        break;
      case "winterwonderland":
        this.changeView("WinterWonderland")
        break;
      case "iceskating":
        this.changeView("IceSkating")
        break;
      case "drones":
        this.changeView("Drones")
        break;
      case "funfleet":
        this.changeView("FunFleet")
        break;
      case "404":
        this.changeView("Four0Four")
        shouldCheckAuth = false
        break;
      default:
        this.changeView("Four0Four")
        shouldCheckAuth = false
        break;
    }
    return shouldCheckAuth
  }

  /*
  Call this to change and update the view.
  This will handle resetting the state for you.
  */
  changeView(newView, tertiaryView = false, fourthView = false, callback = null) {
    //console.log("changeView", newView, tertiaryView, fourthView)

    switch (newView) {
      case "Home": {
        //1) Update the window path.
        Helpers.updateWindowPath("/")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Home",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "About": {
        //1) Update the window path.
        Helpers.updateWindowPath("/about")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "About",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "Contact": {
        //1) Update the window path.
        Helpers.updateWindowPath("/contact")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Contact",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "PumpkinFestival": {
        //1) Update the window path.
        Helpers.updateWindowPath("/pumpkinfestival")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "PumpkinFestival",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "WinterWonderland": {
        //1) Update the window path.
        Helpers.updateWindowPath("/winterwonderland")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "WinterWonderland",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "IceSkating": {
        //1) Update the window path.
        Helpers.updateWindowPath("/iceskating")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "IceSkating",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "Drones": {
        //1) Update the window path.
        Helpers.updateWindowPath("/drones")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Drones",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "FunFleet": {
        //1) Update the window path.
        Helpers.updateWindowPath("/funfleet")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "FunFleet",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      case "Four0Four": {
        //1) Update the window path.
        Helpers.updateWindowPath("/404")
        //2) Get the user info
        this.setState({
          view: "Home",
          subview: "Four0Four",
          tertiaryView: tertiaryView,
          render: this.state.render + 1,
        })
        break;
      }
      default:
        console.log("Unknown Change View:", newView, tertiaryView, fourthView)
        break;
    }
    window.scrollTo(0, 0);
    if (callback !== null && typeof callback === "function") {
      callback()
    }
  }

  /*
  Reloads this user's information and resets the accessAccountID.
  */
  reloadUser() {
    API.checkAuthentication(() => {
      //2) Load the current user's information
      this.setState({
        userInfo: {},
        adminInfo: {},
        accountTakeover: false,
        messages: []
      })
      API.accessAccountID = false
      this.loadUserInfo()
    })
  }

  /*
  * Loads the user information for the user.
  * If accessing an account, then load the info for that user.
  */
  loadUserInfo(callback = null) {
    this.setState({
      loadingUserInfo: true,
      fourthView: "Home",
    }, () => {
      API.callDarwinAPI("GET", "userProfile", {}, (result) => {
        if ("error" in result) {
          this.setState({
            loadingUserInfo: false
          }, () => {
            this.showPopup("Error", "Couldn't get the user profile", result.error)
          })
          return
        }
        console.log("User Profile", result.data)

        //update the user in hubspot.
        if (result.data.user && result.data.user.email) {
          var _hsq = window._hsq = window._hsq || [];
          _hsq.push(['identify', {
            email: result.data.user.email,
            id: result.data.user.id,
            accountType: result.data.user.accountType,
            name: result.data.user.name,
          }]);
        }

        let data = {
          userInfo: result.data,
          loadingUserInfo: false,
        }
        if (!this.state.accountTakeover) {
          data['adminInfo'] = result.data
        }
        this.setState(data, () => {
          if (callback !== null && typeof callback === "function") {
            callback()
          }
        })
      })
    })
  }

  /*
  * Gets the user's profile without a lot of view changes, then calls the callback.
  */
  getUserInfo(callback = null) {
    API.callDarwinAPI("GET", "userProfile", {}, (result) => {
      if ("error" in result) {
        this.setState({
          loadingUserInfo: false
        }, () => {
          this.showPopup("Error", "Couldn't get the user profile", result.error)
        })
        return
      }
      console.log("User Profile", result.data)

      //update the user in hubspot.
      if (result.data.user && result.data.user.email) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['identify', {
          email: result.data.user.email,
          id: result.data.user.id,
          accountType: result.data.user.accountType,
          name: result.data.user.name,
        }]);
      }

      let data = {
        userInfo: result.data,
        loadingUserInfo: false,
      }
      if (!this.state.accountTakeover) {
        data['adminInfo'] = result.data
      }
      this.setState(data, () => {
        if (callback !== null && typeof callback === "function") {
          callback()
        }
      })
    })
  }

  /*
  * Logs the user out of the program and returns to the login page.
  */
  logout() {
    API.logout()
  }

  /*
  * Show the error with a dismiss button
  */
  showPopup(type, title, description) {
    let ds = description
    if (typeof ds === 'object') {
      //turn this into a JSON array
      try {
        ds = JSON.stringify(ds)
      } catch (e) {
        ds = "Unparseable issue"
      }
    }
    this.setState({
      popup: true,
      popupType: type,
      popupTitle: title,
      popupDescription: ds
    })
  }

  /*
  * Dismiss the error message.
  */
  dismissPopup() {
    this.setState({
      popup: false,
      popupTitle: "",
      popupDescription: "",
    })
  }

  /*
  * Shows a confirmation dialog with an optional callback passing true or false
  * to whether they have accepted the action
  */
  showConfirmation(title, description, accept, deny, action) {
    this.setState({
      confirmation: true,
      confirmationTitle: title,
      confirmationDescription: description,
      confirmationAccept: accept,
      confirmationDeny: deny,
      confirmationAction: (result) => {
        this.setState({
          confirmation: false
        })
        action(result)
      }
    })
  }

  /*
  Shows a confirm password prompt over the whole screen.
  Set method to be Password or TFA.
  */
  showConfirmPassword(action, type = "Confirm your password to perform this action.", method = "Password") {
    this.setState({
      confirmPassword: true,
      confirmPasswordType: type,
      confirmPasswordAction: (result) => {
        this.setState({
          confirmPassword: false
        })
        action(result)
      },
      confirmPasswordMethod: method,
    })
  }

  /*
  Shows a toast on the screen.
  */
  showToast(title, message, type) {
    this.setState((old) => {
      //create and add the new toast
      let newToast = {
        id: old.toastID + 1,
        title: title,
        message: message,
        type: type,
      }
      let newToasts = old.toasts
      newToasts.push(newToast)

      return {
        toasts: newToasts,
        toastID: old.toastID + 1
      }
    })
  }

  /*
  Removes the toast by id from the list of toasts.
  */
  removeToast(id) {
    this.setState((old) => {
      let newToasts = old.toasts
      let rindex = 0
      for (let i = 0; i < newToasts.length; i = i + 1) {
        if (id === newToasts[i].id) {
          //found the toast to remove
          rindex = i
          break
        }
      }
      newToasts.splice(rindex, 1)
      return {
        toasts: newToasts
      }
    })
  }

  /*
  * Go back to our account.
  */
  stopAccessing(callback = null) {
    //console.log("Stop Accessing Account")
    this.setState({
      accountTakeover: false,
      userInfo: this.state.adminInfo
    }, () => {
      API.accessAccountID = false
      this.changeView("Profile")
      this.loadUserInfo(callback)
    })
  }

  /*
  Starts accessing an account if possible.
  */
  accessAccount(accountID, callback = null) {
    if (!accountID) {
      console.log("Cannot access a null account.")
      return
    }
    //console.log("Accessing Account:", accountID)
    if (this.state.userInfo.user.admin === "1") {
      this.setState({
        accountTakeover: true,
        userInfo: {}
      }, () => {
        //load this user's information
        API.accessAccountID = accountID
        if (!callback) {
          //go to the home page if no callback
          this.changeView("Profile")
        }
        this.loadUserInfo(callback)
      })
    } else {
      this.showPopup("Error", "You don't have the proper permissions to access this account.", "You must be a Floww Admin to experience this functionality.")
    }
  }

  /*
  A user has just logged in. We should reset and reload everything.
  */
  loggedInUser() {
    //1) Update the view.
    this.changeView("Profile")
    //2) Reload the information about the logged in user to set the accountType, messages, and userInfo
    this.reloadUser()
  }

  /*
  A user has just created an account, determine if there is one outstanding request. If so, then go to that page.
  */
  firstLogin() {
    this.loggedInUser()
  }

  /*
  Returns home and reloads the user information for the current accessed user.
  */
  homeAndReloadUserInfo() {
    //1) Reload the information about the logged in user to set the accountType, messages, and userInfo
    this.loadUserInfo(() => {
      //console.log("LOADED USER INFO", this.state.userInfo.user.accountStatus)
      //2) Update based on the loaded user information.
      switch (this.state.userInfo.user.accountStatus) {
        case "0":
          this.changeView("CreateAccountFinished")
          break;
        //1 - a finalized User.
        case "2":
          //Instructor needs to fill out the form.
          this.changeView("CreateAccountInstructorForm")
          break;
        case "3":
          //Instructor is under review.
          this.changeView("CreateAccountInstructorReview")
          break;
        //4 - a finalized instructor.
        default:
          this.changeView("Profile")
          break;
      }
    })
  }

  render() {

    //shown for a popup.
    let popupProps = {
      main: this.state.popupType,
      title: this.state.popupTitle,
      description: this.state.popupDescription,
      dismiss: this.dismissPopup,
    }
    //shown for a confirmation dialog.
    let confirmationProps = {
      main: "Confirmation",
      title: this.state.confirmationTitle,
      description: this.state.confirmationDescription,
      accept: this.state.confirmationAccept,
      deny: this.state.confirmationDeny,
      action: this.state.confirmationAction
    }
    let confirmPasswordProps = {
      type: this.state.confirmPasswordType,
      action: this.state.confirmPasswordAction,
      method: this.state.confirmPasswordMethod,
    }
    let screenProps = {
      userInfo: this.state.userInfo,
      adminInfo: this.state.adminInfo,
      accountTakeover: this.state.accountTakeover,
      showPopup: this.showPopup,
      showConfirmation: this.showConfirmation,
      showConfirmPassword: this.showConfirmPassword,
      showToast: this.showToast,
      takeoverAccount: this.accessAccount,
      changeView: this.changeView,
      render: this.state.render,
      tertiaryView: this.state.tertiaryView, //usually false, if not false then go to this view.
      fourthView: this.state.fourthView,
      sandbox: this.state.fourthView,
      getUserInfo: this.getUserInfo,
      homeAndReloadUserInfo: this.homeAndReloadUserInfo,
    }

    //default the props from screenProps, then set your own props
    let homeProps = Object.assign({
      //Custom props here
    }, screenProps)
    let four0fourProps = Object.assign({
      //Custom props here
    }, screenProps)

    /*let topBarProps = {
      active: this.state.subview,
      changeView: this.changeView,
      userInfo: this.state.userInfo,
      accountTakeover: this.state.accountTakeover,
      stopTakeover: this.stopAccessing,
    }*/
    let footerProps = {
      changeView: this.changeView,
    }

    return (
      <div className="App">
        { this.state.popup &&
          <Components.Popup {...popupProps} />
        }
        { this.state.confirmation &&
          <Components.Confirmation {...confirmationProps} />
        }
        { this.state.confirmPassword &&
          <Components.ConfirmPassword {...confirmPasswordProps} />
        }
        { this.state.view === "Home" &&
          <span className="AppContainer">
            <div className="MainBody">
              { ["Home", "Four0Four", "About", "Contact", "PumpkinFestival", "WinterWonderland", "IceSkating", "Drones", "FunFleet"].includes(this.state.subview) &&
                <div className="Portal">
                  {/* We are loading the user info, show the full screen loader */}
                  { this.state.loadingUserInfo &&
                    <FullScreenLoading />
                  }
                  {/* We have loaded the user information, show the top bar */}
                  {/* !this.state.loadingUserInfo && this.state.subview !== "Home" &&
                    <Components.TopBar {...topBarProps} />
                  */}
                  {/* Home Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Home" &&
                    <Home.Home {...homeProps} />
                  }
                  {/* About Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "About" &&
                    <About.About {...homeProps} />
                  }
                  {/* Contact Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Contact" &&
                    <Contact.Contact {...homeProps} />
                  }
                  {/* Pumpkin Festival Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "PumpkinFestival" &&
                    <PumpkinFestival.PumpkinFestival {...homeProps} />
                  }
                  {/* Winter Wonderland Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "WinterWonderland" &&
                    <WinterWonderland.WinterWonderland {...homeProps} />
                  }
                  {/* Ice Skating Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "IceSkating" &&
                    <IceSkating.IceSkating {...homeProps} />
                  }
                  {/* Drones Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Drones" &&
                    <Drones.Drones {...homeProps} />
                  }
                  {/* Fun Fleet Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "FunFleet" &&
                    <FunFleet.FunFleet {...homeProps} />
                  }
                  {/* 404 Error Screen */}
                  { !this.state.loadingUserInfo && this.state.subview === "Four0Four" &&
                    <Four0Four.Four0Four {...four0fourProps} />
                  }
                  {/* Footer */}
                  <Components.Footer {...footerProps} />
                </div>
              }
            </div>
          </span>
        }
        {/* Toast Container that will show the toasts on the screen and disappear them after a few seconds */}
        <div className="toastContainer">
          { this.state.toasts.map((toast, _i) => (
            <Toast key={"toast" + toast.id} id={toast.id} title={toast.title} message={toast.message} type={toast.type} removeSelf={this.removeToast.bind(this, toast.id)} />
          ))}
        </div>
      </div>
    );
  }
}

/*
 * A full screen loading box that can be used while the app is loading.
 */
export class FullScreenLoading extends React.Component {

  render() {
    return (
      <div className="FullScreenLoading">
        <Components.LoadingIndicator color="dark" size="medium" />
      </div>
    )
  }
}

// A popup that appears shortly and then disappears
// Call the show method on this element to show the action popup
export class Toast extends React.Component {

  constructor(props) {
    super(props)

    this.unmounting = false
    this.state = {
      showing: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        showing: true
      })
      setTimeout(() => {
        if (!this.unmounting) {
          this.setState({
            showing: false
          })
          setTimeout(() => {
            //remove ourselves from the super user
            this.props.removeSelf()
          }, 1500)
        }
      }, 4000) //this is how long it stays on screen in ms.
    }, 100)
  }

  componentWillUnmount() {
    this.unmounting = true
  }

  render() {
    let classes = "toast "
    let classImage = ImageToastBlack
    if (this.state.showing === true) {
      classes += "toastVisible "
    }
    switch (this.props.type) {
      case "blue":
        classes += "toastBlue"
        classImage = ImageToastBlue
        break;
      case "red":
        classes += "toastRed"
        classImage = ImageToastRed
        break;
      case "green":
        classes += "toastGreen"
        classImage = ImageToastGreen
        break;
      default:
        classes += "toastWhite"
        classImage = ImageToastBlack
        break;
    }

    return (
      <div className={classes}>
        <img src={classImage} alt="Message"/>
        <div className="toastContent">
          <div className="toastTitle">{this.props.title}</div>
          <div className="toastMessage">{this.props.message}</div>
        </div>
      </div>
    )
  }
}