import React from 'react';
import autoBind from 'react-autobind';

// import * as Components from '../Components.js';
//import * as Generation from '../Generation.js';
// import * as Helpers from '../Helpers.js';

//import the Darwin API classes
// import API from '../API.js';
import * as Home from './Home.js'

export class About extends React.Component {

  constructor(props) {
    super(props)

    let data = {}

    this.state = {
      loading: false,
      data: data,
      forceCheck: false,
      shakeButton: false,

      view: "Home",
      subview: "Home",
      abilities: [],
      actions: [],

      selectedImage: false,
      selectedImageIndex: -1,
      nextImage: false,
      previousImage: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    this.calculateView()
  }

  componentDidUpdate(prevProps) {
    if (this.props.render !== prevProps.render) {
      //reload the home view.
      this.calculateView()
    }
  }

  calculateView() {
  }

  /*
  * Submits the form
  */
  submitForm() {
    //1) Make sure we have all of the data.
    let valid = true
    let requiredFields = []
    let requiredIf = []
    let requiredSometimes = [] //if set, then don't remove it from the data.
    let optionals = [] //the optional fields apart from the requiredFields.
    switch (this.state.view) {
      case "Home":
        requiredFields = []
        optionals = []
        requiredIf = []
        requiredSometimes = []
        break;
      default:
        break;
    }
    requiredFields.forEach((element) => {
      if (this.state.data[element] === undefined || this.state.data[element].value === undefined) {
        //the field is not filled out
        console.log("required not filled out: ", element)
        valid = false
      }
    })
    requiredIf.forEach((condition) => {
      if (this.state.data[condition.field] === undefined || this.state.data[condition.field].value === undefined) {
        //the condition field is not filled out
        console.log("condition field is not filled out: ", condition)
        valid = false
      } else if (condition.values !== undefined && condition.values.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required and is not filled out: ", condition)
          valid = false
        }
      } else if (condition.not !== undefined && !condition.not.includes(this.state.data[condition.field].value)) {
        //this field is required
        if (this.state.data[condition.require] === undefined || this.state.data[condition.require].value === undefined) {
          //and is not filled out
          console.log("rif field is required with not and is not filled out: ", condition)
          valid = false
        }
      }
    })
    //2) Make sure every data element is valid.
    for (let key in this.state.data) {
      let forget = false
      //Make sure it is not a requiredSometimes
      if (!requiredSometimes.includes(key)) {
        // Check the requiredIf conditions
        for (let i = 0; i < requiredIf.length; i = i + 1) {
          let rif = requiredIf[i]
          if (rif.require === key) {
            if (!rif.values.includes(this.state.data[rif.field].value)) {
              if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                forget = true
              }
            }
            break
          }
        }
      }
      //Make sure this is a field we are looking for.
      if (optionals.includes(key) || requiredFields.includes(key)) {
        if (!forget && this.state.data[key].valid === false) {
          console.log("data not valid", key)
          valid = false
        }
      }
    }

    //3) If valid, submit, else force check the fields for display.
    if (valid) {
      let data = {}
      for (let key in this.state.data) {
        let forget = false
        //Make sure it is not a requiredSometimes
        if (!requiredSometimes.includes(key)) {
          for (let i = 0; i < requiredIf.length; i = i + 1) {
            let rif = requiredIf[i]
            if (rif.require === key) {
              if (!rif.values.includes(this.state.data[rif.field].value)) {
                if (rif.not === undefined || rif.not.includes(this.state.data[rif.field].value)) {
                  forget = true
                }
              }
              break
            }
          }
        }
        if (!forget) {
          if (optionals.includes(key) || requiredFields.includes(key)) {
            if (this.state.data[key].value !== false && this.state.data[key].value.toString().length > 0) {
              data[key] = this.state.data[key].value
              if (typeof data[key] === 'string') {
                data[key] = data[key].trim()
              }
            }
          }
        }
      }
      //Submit the form
      switch (this.state.view) {
        case "Home":
          //this.updateFees(data)
          break;
        default:
          break;
      }
    } else {
      console.log("Form not valid to submit")
      this.setState({
        forceCheck: true
      })
      //Shake the submit button as it is invalid to submit
      this.shakeTheButton()
      return
    }
  }

  /*
  * Called when data in an input form changes.
  * This will update the state.data param with the
  * name and new value of the form element.
  */
  formChanged(name, value, valid) {
    this.setState((prevState) => {
      let d = prevState.data
      d[name] = {
        value: value,
        valid: valid
      }
      return {
        data: d,
        forceCheck: false
      }
    }, () => {
    })
  }

  /*
  Shakes the button and then removes the class.
  */
  shakeTheButton() {
    this.setState({
      shakeButton: true
    }, () => {
      setTimeout(() => {
        this.setState({
          shakeButton: false
        })
      }, 1000)
    })
  }
  
  render() {

    return (
      <div className="Home">
        <Home.HomeTopLinks />
        <div className="AboutUs">
          <div>About Us</div>
          <div>
            Peter Pan's Backyard creates magical and immersive experiences for families and communities,
            offering a wide range of events such as ice skating rinks, drone shows, winter wonderlands, and
            pumpkin festivals. By transforming ordinary spaces into enchanting destinations, they bring joy
            and wonder to all ages year-round.
          </div>
        </div>
        <div className="AboutUsDetail">
          Peter Pan's Backyard was founded during the challenging days of the Covid pandemic by two Rice
          University graduates who shared a simple but powerful vision: to bring freedom, joy, and happiness
          to families during a time when the world felt anything but magical. What began as small pop-up
          events quickly grew into a beloved community experience, with ice skating rinks, enchanting
          winter wonderlands, dazzling drone light shows, and festive pumpkin patches. Peter Pan's Backyard
          is more than just an events company—it’s a space where people of all ages can escape the everyday
          and immerse themselves in moments of wonder and togetherness.
        </div>
        <div className="AboutUsDetail">
          With dreams of one day creating a Disney World-like destination, Peter Pan's Backyard aspires to
          be a place where families can connect, laugh, and make memories that last a lifetime. Our founders
          believe that the magic of shared experiences can transform lives, and they are committed to building
          a world of joy, one event at a time. From whimsical attractions to innovative shows, every detail
          is designed to spark delight and inspire the imagination, reminding us all that a little bit of
          magic can go a long way.
        </div>
      </div>
    )
  }
}